import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import AgentFavicon from '~/pages/heineken_template/components/AgentFavicon';
import { store } from '~/pages/heineken_template/_private/store';
import { useElementAsComponent } from '~/pages/heineken_template/_private/useElementAsComponent';
const classes = {
    Root: styled.div ``,
    LeftRoot: styled.div ``,
    RightRoot: styled.div ``,
};
const defaultsCssset = css `
  &${classes.Root} {
    ${flex.h.allCenter};
    width: 100%;
    justify-content: space-between;
    padding: 0 8px;
  }

  ${classes.LeftRoot} {
    ${flex.h.allCenter};
    gap: 8px;
  }

  ${classes.RightRoot} {
    ${flex.h.allCenter};
    gap: 8px;
  }
`;
export const Preset_Topbar = memo(function Topbar(props) {
    const { isPc } = useMedia();
    const ComponentsInRight = useElementAsComponent(props.componentsInRight);
    const ComponentsInLeft = useElementAsComponent(props.componentsInLeft);
    if (props.hideIfPcView && isPc) {
        return null;
    }
    return (<classes.Root className={props.className} css={defaultsCssset}>
      <classes.LeftRoot>
        {isPc && <AgentFavicon.Display />}
        {!isPc && props.showLeftBurger && (<store.drawerLeft.DrawerToggle css={css `
              height: 48px;
              width: 48px;
            `}/>)}

        <ComponentsInLeft />
      </classes.LeftRoot>

      <classes.RightRoot>
        <ComponentsInRight />

        {!isPc && props.showRightBurger && (<store.drawerRight.DrawerToggle css={css `
              height: 48px;
              width: 48px;
            `}/>)}
      </classes.RightRoot>
    </classes.Root>);
});
