import { useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { proxy, snapshot } from 'valtio';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { fr_events } from '~/pages/heineken_template/_fr/fr_events';
import { Event } from '~/pages/heineken_template/_fr/fr_events/_Event';
export class EventOfDocumentVisibilityChange extends Event {
    data = proxy({
        visibility: 'hidden',
        isVisible: false,
    });
    constructor() {
        super('documentVisibilityChange');
    }
    /**
     * @example
     *   //
     *   // 飯粒
     *   useEffect(() => {
     *     const unSub = fr_events.onVisible(this.name, () => {
     *       console.info('正在被看')
     *     })
     *
     *     return function GC() {
     *       unSub()
     *     }
     *   }, [])
     */
    onVisible(callback) {
        return fr_events.emitter.on(this.name, (data) => {
            if (data.isVisible) {
                callback(data);
            }
        });
    }
    /**
     * @example
     *   //
     *   // 飯粒
     *   useEffect(() => {
     *     const unSub = fr_events.onVisible(this.name, () => {
     *       console.info('正在被看')
     *     })
     *
     *     return function GC() {
     *       unSub()
     *     }
     *   }, [])
     */
    onHidden(callback) {
        return fr_events.emitter.on(this.name, (data) => {
            if (!data.isVisible) {
                callback(data);
            }
        });
    }
    useInstall = () => {
        const dispatchData = useCallback(() => {
            this._dispatchData();
        }, []);
        const documentListener = useCallback(() => function () {
            dispatchData();
        }.call(globalThis.document), [dispatchData]);
        //
        // 頁面一進入時，先 update 當前頁面狀態一次。避免頁面進來時，狀態被預設在「hidden」
        useEffectOnce(() => {
            this._dispatchData();
        });
        //
        // 安裝事件
        useEffectOnce(() => {
            debugAPI.fr_events.log(`useInstall(): 已安裝`);
            document.addEventListener('visibilitychange', documentListener);
            return () => {
                debugAPI.fr_events.log(`useInstall(): 解除安裝`);
                document.removeEventListener('visibilitychange', documentListener);
            };
        });
    };
    _dispatchData() {
        const isVisibility = document.visibilityState === 'visible';
        this.data.visibility = document.visibilityState;
        this.data.isVisible = isVisibility;
        fr_events.emitter.emit(this.name, snapshot(this.data));
    }
}
