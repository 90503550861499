import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { flex } from '~/modules/AppLayout/FlexGridCss';
const classes = {
    Root: styled.div ``,
    Icon: styled.div ``.withComponent('img'),
};
const defaultsCssset = css `
  &${classes.Root} {
    ${flex.inline.allCenter};
  }

  ${classes.Icon} {
    width: 40px;
    height: 40px;
  }
`;
export const Display = memo(function AgentFavicon(props) {
    return (<classes.Root className={props.className} css={defaultsCssset}>
      <classes.Icon src={`${fr_agents.config.favicon}`}/>
    </classes.Root>);
});
export default {
    Display,
    classes,
};
